export const userDetail = {
    img: "https://i.picsum.photos/id/1015/6000/4000.jpg?hmac=aHjb0fRa1t14DTIEBcoC12c5rAXOSwnVlaA5ujxPQ0I",
    title: "Lyn Knight Auction",
    subTitle: "Lorem Sub heading",
    statsDescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut a laoreet sapien. Sed mi est, aliquet vel velit sit amet, aliquam posuere leo. Cras varius risus id diam ullamcorper volutpat.",
    statsItem: [
        {
            key: "Items",
            value: "22"
        },
        {
            key: "Owners",
            value: "32"
        },
        {
            key: "Floor price",
            value: "15"
        },
        {
            key: "Volume Traded",
            value: "84"
        }
    ]
}